<template>
  <div class="pt-8 pb-8 pa-0" id="register">
    <BannerPage v-if="headerSection" 
      icon="mdi-account" 
      :title="$t('message.reg_form_header')" 
      :subtitle="$t('message.reg_form_header_text')">
    </BannerPage> 

    <v-skeleton-loader type="article" tile :loading="registerPageLoading">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>                    
    </v-overlay>  
    <v-form ref="registerForm" v-model="valid">
      <v-row v-if="verifySection">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-title class="userData_title">{{$t('message.reg_form_verification_header')}}</v-card-title>
            <v-card-subtitle class="userData_subtitle">{{$t('message.reg_form_verification_header_text')}}</v-card-subtitle>
            <v-card-text class="userData_content">
              <v-row class="userData_row">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-for="(field, i) in verifySection.field_list" :key="'field-' + i"
                    class="userData_input"
                    v-model="formInputs[i]" 
                    :label="$t('message.' + field.field_label) + '*'" 
                    :hint="$t('message.' + field.comment)"
                    :persistent-hint="field.comment && field.comment.length > 0 ? true : false"
                    :rules="formImputsRules" 
                    :disabled="verified"></v-text-field>                  
                  <vue-recaptcha sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" 
                    :loadRecaptchaScript="true"
                    @verify="captchaVerified = true"
                    @error="captchaVerified = false">
                    <v-btn class="userData_button primaryColorBg white--text mt-6"
                      :disabled="!valid || verified" 
                      @click="onClickVerify">
                      {{$t('message.reg_form_verification_button')}}
                    </v-btn>
                  </vue-recaptcha>                     
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="verified && usernameSection">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-title class="inputEmail_title">{{$t('message.reg_form_username_header')}}</v-card-title>
            <v-card-text class="inputEmail_content">
              <v-row class="inputEmail_row">
                <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      class="inputEmail_email"
                      :hint="$t('message.reg_form_verification_email_comment')"
                      :persistent-hint="$t('message.reg_form_verification_email_comment') && 
                        $t('message.reg_form_verification_email_comment').length > 0 ? true : false"
                      v-model="email" 
                      :rules="emailRules" 
                      :label="$t('message.reg_form_verification_email_label') + '*'"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="verified && profileSection">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-title class="profile_title">{{$t('message.reg_form_profile_header')}}</v-card-title>
            <v-card-subtitle class="profile_subtitle">{{$t('message.reg_form_profile_header_text')}}</v-card-subtitle>
            <v-card-text class="profile_content">
              <v-row v-for="(group, i) in profileSection.groups" :key="'group-' + i" class="profile_row">
                <v-col cols="12" sm="6" md="6">
                  <v-card-subtitle v-if="group.label && $t('message.' + group.label) && $t('message.' + group.label) != ''" class="profile_group_label">{{$t('message.' + group.label)}}</v-card-subtitle>
                  <span v-if="group.comment && $t('message.' + group.comment) && $t('message.' + group.comment) != ''" class="profile_group_comment">{{$t('message.' + group.comment)}}</span>
                  <template v-for="(field, j) in group.field_list">
                    <v-checkbox v-if="field.format == 'bool'"
                      :key="'field-' + j"
                      class="profile_input"
                      v-model="field.value"
                      :label="$t('message.' + field.field_label) + (field.required ? '*' : '')"
                      :hint="$t('message.' + field.comment)"
                      :persistent-hint="$t('message.' + field.comment) && $t('message.' + field.comment).length > 0 ? true : false"
                      :disabled="field.actions == 3">
                    </v-checkbox>
                    <v-select v-else-if="field.format == 'dropdown' || field.format == 'naz' || field.format == 'prov'"
                      :key="'field-' + j"
                      class="profile_input"
                      v-model="field.value"
                      :items="field.dropdown"
                      :item-text="field.dropdown.text"
                      :item-value="field.dropdown.value"
                      :label="$t('message.' + field.field_label) + (field.required ? '*' : '')"
                      :hint="$t('message.' + field.comment)"
                      :persistent-hint="$t('message.' + field.comment) && $t('message.' + field.comment).length > 0 ? true : false"
                      :disabled="field.actions == 3"
                      return-object
                      @change="changeDropdown(field)"
                      @click="clickDropdown(field)">
                    </v-select>
                    <v-text-field v-else
                      :key="'field-' + j"
                      class="profile_input"
                      v-model="field.value" 
                      :label="$t('message.' + field.field_label) + (field.required ? '*' : '')" 
                      :hint="$t('message.' + field.comment)"
                      :persistent-hint="$t('message.' + field.comment) && $t('message.' + field.comment).length > 0 ? true : false"
                      :rules="profileRules(field)" 
                      :disabled="field.actions == 3"
                      :type="field.format == 'date' ? 'date' : 'text'">
                    </v-text-field> 
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="verified && profileSection && showAcceptanceSection">
        <v-col cols="12">
          <v-card tile flat class="border">
            <v-card-title class="accept_title">{{$t('message.reg_form_acceptance_header')}}</v-card-title>
            <v-card-subtitle class="accept_subtitle"><span v-html="$t('message.reg_form_acceptance_general_text')"></span></v-card-subtitle>
            <v-card-text class="accept_content">
              <!-- Termini e condizioni -->
              <v-row v-if="showContractAcceptance" class="contract_row">
                <v-col cols="12">
                  <div class="contract_text multi-row"
                    v-html="$t('message.' + acceptanceSection.contract_releases.contract_text)"></div>

                  <div v-if="acceptanceSection.contract_releases.contract_declaration && $t('message.' + acceptanceSection.contract_releases.contract_declaration).length > 0" 
                    class="contract_declaration" v-html="$t('message.' + acceptanceSection.contract_releases.contract_declaration)"></div>

                  <div v-if="acceptanceSection.contract_releases.contract_file_pdf && acceptanceSection.contract_releases.contract_file_pdf.length > 0"
                    class="contract_pdf">
                    <v-btn icon @click="downloadPdf(acceptanceSection.contract_releases.contract_file_pdf)">
                      <v-icon>mdi-file-pdf</v-icon>
                    </v-btn>
                    <span class="contract_file_pdf_description" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.contract_file_pdf_description)"></span>
                  </div>

                  <v-checkbox class="contract_checkbox" 
                    v-model="contractChecked" 
                    :label="$t('message.' + acceptanceSection.contract_releases.contract_text_check)"></v-checkbox>
                </v-col>
              </v-row>

              <!-- Clausole vessatorie -->
              <v-row v-if="showVessatoryAcceptance" class="vessatory_row">
                <v-col cols="12">
                  <div class="vessatory_text multi-row"
                    v-html="$t('message.' + acceptanceSection.contract_releases.vessatory_declaration)"></div>

                  <v-checkbox class="vessatory_checkbox" 
                    v-model="vessatoryChecked" 
                    :label="$t('message.' + acceptanceSection.contract_releases.vessatory_text_check)"></v-checkbox>
                </v-col>
              </v-row>

              <!-- Privacy -->
              <v-row v-if="showPrivacyAcceptance" class="privacy_row">
                <v-col cols="12">
                  <div class="privacy_text multi-row"
                    v-html="$t('message.' + acceptanceSection.contract_releases.privacy_text)"></div>

                  <div v-if="acceptanceSection.contract_releases.privacy_file_pdf && acceptanceSection.contract_releases.privacy_file_pdf.length > 0"
                    class="privacy_pdf">
                    <v-btn icon @click="downloadPdf(acceptanceSection.contract_releases.privacy_file_pdf)">
                      <v-icon>mdi-file-pdf</v-icon>
                    </v-btn>
                    <span class="privacy_file_pdf_description" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.privacy_file_pdf_description)"></span>
                  </div>

                  <div v-if="acceptanceSection.contract_releases.privacy_presence_check == 1" class="privacy_declaration">
                    <span class="privacy_declaration_text multi-row" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.privacy_declaration)"></span>
                    <v-checkbox class="privacy_checkbox" 
                      v-model="privacyChecked1" 
                      :label="$t('message.' + acceptanceSection.contract_releases.privacy_text_check)"></v-checkbox>
                  </div>

                  <div v-if="acceptanceSection.contract_releases.privacy_presence_check2 == 1" class="privacy_declaration">
                    <span class="privacy_declaration_text multi-row" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.privacy_declaration2)"></span>
                    <v-checkbox class="privacy_checkbox" 
                      v-model="privacyChecked2" 
                      :label="$t('message.' + acceptanceSection.contract_releases.privacy_text_check2)"></v-checkbox>
                  </div>

                  <div v-if="acceptanceSection.contract_releases.privacy_presence_check3 == 1" class="privacy_declaration">
                    <span class="privacy_declaration_text multi-row" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.privacy_declaration3)"></span>
                    <v-checkbox class="privacy_checkbox" 
                      v-model="privacyChecked3" 
                      :label="$t('message.' + acceptanceSection.contract_releases.privacy_text_check3)"></v-checkbox>
                  </div>

                  <div v-if="acceptanceSection.contract_releases.privacy_presence_check4 == 1" class="privacy_declaration">
                    <span class="privacy_declaration_text multi-row" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.privacy_declaration4)"></span>
                    <v-checkbox class="privacy_checkbox" 
                      v-model="privacyChecked4" 
                      :label="$t('message.' + acceptanceSection.contract_releases.privacy_text_check4)"></v-checkbox>
                  </div>

                  <div v-if="acceptanceSection.contract_releases.privacy_presence_check5 == 1" class="privacy_declaration">
                    <span class="privacy_declaration_text multi-row" 
                      v-html="$t('message.' + acceptanceSection.contract_releases.privacy_declaration5)"></span>
                    <v-checkbox class="privacy_checkbox" 
                      v-model="privacyChecked5" 
                      :label="$t('message.' + acceptanceSection.contract_releases.privacy_text_check5)"></v-checkbox>
                  </div>
                </v-col>
              </v-row>

              <v-btn :disabled="confirmButtonDisabled" 
                @click="onClickConfirm" 
                class="accept_button primaryColorBg white--text">
                {{$t('message.reg_form_confirm_button_name')}}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    </v-skeleton-loader>

    <Confirmation ref="myConfirmation"></Confirmation>

    <Snackbar ref="mySnackbar"></Snackbar> 
  </div>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";
import BannerPage from './BannerPage.vue';
import Snackbar from './Snackbar.vue';
import Confirmation from './Confirmation.vue';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'register',
  components: {
      BannerPage, 
      Snackbar,
      Confirmation,
      VueRecaptcha
  },
  data: function () {
    return {
      headerSection: null,
      usernameSection: null,
      profileSection: null,
      acceptanceSection: null,
      verifySection: null,

      email: "",
      cod_client: null,
      registryId: null,
      
      emailRules: [this.checkRequired, this.checkEmail],      
      formInputs: [],
      formImputsRules: [this.checkRequired], 
      overlay: false,
      registerPageLoading : true,

      contractChecked: false,
      privacyChecked1: false,
      privacyChecked2: false,
      privacyChecked3: false,
      privacyChecked4: false,
      privacyChecked5: false,
      
      valid: true,
      verificationEmail: null,

      verified: false,
      vessatoryChecked: false,
      captchaVerified: false,
      userIp: null
    }
  },
  computed: {
    confirmButtonDisabled: function () {
      if (!this.valid)
        return true;

      if (this.showContractAcceptance && !this.contractChecked)
        return true;

      if (this.showVessatoryAcceptance && !this.vessatoryChecked)
        return true;

      if (this.showPrivacyAcceptance) {
        if (this.acceptanceSection.contract_releases.privacy_presence_check == 1 && 
          this.acceptanceSection.contract_releases.privacy_text_required == 1 && 
          !this.privacyChecked1)
          return true;

        if (this.acceptanceSection.contract_releases.privacy_presence_check2 == 1 && 
          this.acceptanceSection.contract_releases.privacy_text_required2 == 1 && 
          !this.privacyChecked2)
          return true;

        if (this.acceptanceSection.contract_releases.privacy_presence_check3 == 1 && 
          this.acceptanceSection.contract_releases.privacy_text_required3 == 1 && 
          !this.privacyChecked3)
          return true;

        if (this.acceptanceSection.contract_releases.privacy_presence_check4 == 1 && 
          this.acceptanceSection.contract_releases.privacy_text_required4 == 1 && 
          !this.privacyChecked4)
          return true;

        if (this.acceptanceSection.contract_releases.privacy_presence_check5 == 1 && 
          this.acceptanceSection.contract_releases.privacy_text_required5 == 1 && 
          !this.privacyChecked5)
          return true;
      }

      return false;
    },
    showAcceptanceSection: function () {
      if (!this.acceptanceSection)
        return false;

      return this.acceptanceSection.reg_form_contract_acceptance == '1' || 
        this.acceptanceSection.reg_form_privacy_acceptance == '1' || 
        this.acceptanceSection.reg_form_vessatory_acceptance == '1';
    },
    showContractAcceptance: function () {
      if (!this.acceptanceSection)
        return false;

      return this.acceptanceSection.reg_form_contract_acceptance == '1' && 
        this.acceptanceSection.contract_releases.contract_text && 
        this.acceptanceSection.contract_releases.contract_text.length > 0;
    },
    showPrivacyAcceptance: function () {
      if (!this.acceptanceSection)
        return false;

      return this.acceptanceSection.reg_form_privacy_acceptance == '1' && 
        this.acceptanceSection.contract_releases.privacy_text  && 
        this.acceptanceSection.contract_releases.privacy_text .length > 0;
    },
    showVessatoryAcceptance: function () {
      if (!this.acceptanceSection)
        return false;

      return this.acceptanceSection.reg_form_vessatory_acceptance == '1' && 
        this.acceptanceSection.contract_releases.vessatory_declaration && 
        this.acceptanceSection.contract_releases.vessatory_declaration.length > 0;
    }
  },
  methods: {
    changeDropdown: function(field) {
      var that = this;
      if(field.format == 'naz') {
        for(var group in that.profileSection.groups) {
          that.profileSection.groups[group].field_list.forEach(x => {
            if (x.referred_field == field.id && x.format == 'prov') {
              x.value = {};
              x.dropdown = [];
            }
          })
        }
      }    
    }, 
    checkDecimal: function (value) {
      if (value == null || value.toString().length == 0)
        return true;
      return /^\d+(?:[.,]\d+)?$/.test(value) ||  this.$t("message.register_check_decimal");
    },
    checkEmail: function (value) {
      if (value == null || value.toString().length == 0)
        return true;
      return /^.+@.+\..+$/.test(value) || this.$t("message.register_check_email");
    },
    checkInt: function (value) {
      if (value == null || value.toString().length == 0)
        return true;
      return /^\d+$/.test(value) || this.$t("message.register_check_int");
    },
    checkPhone: function (value) {
      if (value == null || value.toString().length == 0)
        return true;   
      return /^[+]{1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.,\\/0-9]*$/.test(value) || this.$t("message.register_check_phone");
    },
    checkRequired: function (value) {
      if (value == null || value.toString().length == 0)
        return 'Testo necessario.'; this.$t("message.register_check_text_required");
      return true;
    },
    checkTextOnly: function (value) {
      if (value == null || value.toString().length == 0)
        return true;
      return /^[a-zA-Z]+$/.test(value) || this.$t("message.register_text_only");
    },   
    clickDropdown: function(field) {
      var that = this;
      var ref = "";
      if(field.format == 'prov') {
        for(var group in that.profileSection.groups) {
          that.profileSection.groups[group].field_list.forEach(x => {
            if (x.id == field.referred_field) {
              ref = x.value.value;
            }
          })
        }
        services.apiCallerRegistration.getDropdownValues('prov', ref)
          .then(res => {
            field.dropdown = res.data;
          }) 
      }    
    }, 
    downloadPdf: function (base64String) {
      var data = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
      var blob = new Blob([data], {type: "octet/stream"});

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = 'file.pdf';
      link.click();
    },
    getFormInputsValue: function (field) {
      var index = this.verifySection.field_list.findIndex(x => x.field == field);
      if (index < 0)
        return null;

      return this.formInputs[index];
    },
    getUserInfo: function() {
      var json = {};
      json['cod_client'] = this.cod_client;
      for(var group in this.profileSection.groups) {
        this.profileSection.groups[group].field_list.forEach(field => {
          if(field.format == 'dropdown' || 
            field.format == 'naz' ||
            field.format == 'prov') {
            json[field.field] = field.value.value;
          }
          else if(field.format == 'date') {
            json[field.field] = field.value;
          }
          else {
            json[field.field] = field.value;
          }
        })
      }

      return json;
    },
    onClickConfirm: async function () {
      var that = this;
      that.$refs.mySnackbar.close();

      if (this.confirmButtonDisabled)
        return;

      this.overlay = true;

      var data = {
        'cod_client': this.cod_client,
        'id_from_users_table': this.registryId,
        'email': this.email,
        'release': this.acceptanceSection.contract_releases.name,
        'contract_acceptance': this.contractChecked && this.showContractAcceptance ? '1' : '0',
        'vessatory_acceptance': this.vessatoryChecked && this.showVessatoryAcceptance ? '1' : '0',
        'privacy_acceptance': this.privacyAccepted(1) ? '1' : '0',
        'privacy_acceptance2': this.privacyAccepted(2) ? '1' : '0',
        'privacy_acceptance3': this.privacyAccepted(3) ? '1' : '0',
        'privacy_acceptance4': this.privacyAccepted(4) ? '1' : '0',
        'privacy_acceptance5': this.privacyAccepted(5) ? '1' : '0',
        'ip_user': this.userIp
      };

      var message = 'Comunication error.';
      var ok = false;
      services.apiCallerRegistration.saveProfile(this.getUserInfo())
        .then((res) => {
          if(res.data) {
            if (res.data.success) {
              services.apiCallerRegistration.saveAcceptances(data)
                .then(async (response) => {
                  if(response) {
                    if(response.data.registration == 'ok') {
                      if (await that.$refs.myConfirmation.open(
                          that.$t("message.reg_form_dialog_title"), 
                          that.$t("message." + response.data.message), 
                          true, 
                          { 'accept': that.$t("message.register_accept"), 'decline': that.$t("message.register_decline") }

                      )) {
                          location.href="/Home";
                          ok = true;
                      }                        
                    }

                    message = that.$t("message." + response.data.message);
                  } 
                })
                .catch(error => {
                  console.log(error);
                })
                .finally(() => {
                  if(!ok)
                    that.$refs.mySnackbar.open(message);
                  that.overlay = false;
                })
            }
            else {
              message = that.$t("message." + res.data.message);
            }
          }
        })
        .catch(error => {
          console.log(error);
          that.$refs.mySnackbar.open(message);
          that.overlay = false;
        })    
    }, 
    onClickVerify: function () {
      var that = this;

      if (!this.$refs.registerForm.validate())
        return;

      this.overlay = true;

      var data = {};
      for (var i = 0, l = this.formInputs.length; i < l; i++) {
        data[this.verifySection.field_list[i].field] = this.formInputs[i];
      }

      services.apiCallerRegistration.verify(data)
        .then(response => {
          that.verified = response.data.verified;

          if (!response.data.verified) {
            that.$refs.mySnackbar.open(that.$t("message.reg_form_verification_error_text"));
          }
          else {
            that.$refs.mySnackbar.close();
            that.email = response.data.email;
            that.registryId = response.data.id;
            that.cod_client = response.data.cod_client;
            services.apiCallerRegistration.getProfileSection(that.cod_client)
              .then(res => {
                that.profileSection = res.data;
                for(var group in that.profileSection.groups) {
                  that.profileSection.groups[group].field_list.forEach(field => {
                    if (field.format == 'prov') {
                      field.dropdown = [field.value];
                    }
                    else if(field.format == 'date') {
                      field.value = field.value.substring(0,10);
                    }
                  })
                }
                this.$refs.registerForm.validate();
              })
          }
        })
        .catch(error => {
          console.log(error);
          that.$refs.mySnackbar.open('Comunication error.');
        })
        .finally(() => {
          that.overlay = false;
        });
    },
    openPdf: function (base64String) {
      let pdfWindow = window.open("");
      pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(base64String) + "'></iframe>"
      );
    },
    privacyAccepted: function (privacyNum) {
      if (!this.showPrivacyAcceptance)
        return false;

        switch(privacyNum)
        {
          case 1:
            if (this.acceptanceSection.contract_releases.privacy_presence_check == 1 && 
                this.acceptanceSection.contract_releases.privacy_text_required == 1 && 
                !this.privacyChecked1)
                return false;
            break;
          case 2:
            if (this.acceptanceSection.contract_releases.privacy_presence_check2 == 1 && 
                this.acceptanceSection.contract_releases.privacy_text_required2 == 1 && 
                !this.privacyChecked2)
                return false;
            break;
          case 3:
            if (this.acceptanceSection.contract_releases.privacy_presence_check3 == 1 && 
                this.acceptanceSection.contract_releases.privacy_text_required3 == 1 && 
                !this.privacyChecked3)
                return false;
            break;
          case 4:
            if (this.acceptanceSection.contract_releases.privacy_presence_check4 == 1 && 
                this.acceptanceSection.contract_releases.privacy_text_required4 == 1 && 
                !this.privacyChecked4)
                return false;
            break;
          case 5:
            if (this.acceptanceSection.contract_releases.privacy_presence_check5 == 1 && 
                this.acceptanceSection.contract_releases.privacy_text_required5 == 1 && 
                !this.privacyChecked5)
                return false;
            break;
          default:
            return false;
      }
      return true;
    },    
    profileRules: function (field) {
      var rules = [];
      if(field.required) {
        rules.push(this.checkRequired)
      }
    
      //check format
      switch(field.format) {
        case 'textonly':
          rules.push(this.checkTextOnly);
          break;
        case 'int':
          rules.push(this.checkInt);
          break;
        case 'decimal':
          rules.push(this.checkDecimal);
          break;
        case 'email' :
          rules.push(this.checkEmail);
          break;
        case 'phone' :
          //set default phone code
          if (field.value == null || field.value.toString().length == 0)
            field.value = this.profileSection.phone_code;
          rules.push(this.checkPhone);
          break;
        default:
          break;
      }

      return rules;
    }    
  },
  created: function () {
    var that = this;

    fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        that.userIp = ip;
    });
    
    var requests = [];
    requests.push(services.apiCallerRegistration.getHeaderSection());
    requests.push(services.apiCallerRegistration.getUsernameSection());
    requests.push(services.apiCallerRegistration.getVerifySection());
    requests.push(services.apiCallerRegistration.getAcceptanceSection());

    Promise.all(requests)
      .then(responses => {
        that.headerSection = responses[0].data;
        that.usernameSection = responses[1].data;
        that.verifySection = responses[2].data; 
        that.acceptanceSection = responses[3].data;  
      })
      .catch(error => {
        console.log(error);
        that.$refs.mySnackbar.open('Errore di comunicazione.');
      })
      .finally(() => {
        that.registerPageLoading = false;
      });
  }
};

</script>

